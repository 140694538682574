import axios from 'axios'
import { Config } from '@/config'

const publicPath = Config.publicPath ? `${Config.publicPath}` : '/'

let siteKey = process.env.VUE_APP_RECAPTCHA_SITEKEY || ''

// Fetches site key from json file
export const RecaptchaSiteKeyService = {
  async getSiteKey(): Promise<string> {
    if (siteKey) {
      return siteKey
    } else {
      const resp = await axios.get<any>(`${publicPath}recaptcha-sitekey.json`)

      siteKey = resp.data.siteKey || ''

      return siteKey
    }
  }
}
