<template>
  <div class="g-recaptcha"
       :data-sitekey="this.siteKey"
       data-callback="recaptchaResponse"
  >
  </div>
</template>

<script>

import Vue from 'vue'
import Component from 'vue-class-component'
import { Emit } from 'vue-property-decorator'
import { RecaptchaSiteKeyService } from '@/services/RecaptchaSiteKeyService'

@Component
export default class Recaptcha extends Vue {
  data() {
    return {
      siteKey: ''
    }
  }

  async beforeCreate() {
    this.siteKey = await RecaptchaSiteKeyService.getSiteKey()
  }

  @Emit('recaptchaToken')
  recaptchaResponse(token) {
    return token
  }

  destroyed() {
    delete window.recaptchaResponse
  }

  beforeMount() {
    const script = document.createElement('script')
    script.src = `https://www.google.com/recaptcha/enterprise.js?hl=${this.$i18n.locale}`
    script.async = true
    script.defer = true

    document.head.appendChild(script)

    window.recaptchaResponse = this.recaptchaResponse
  }

  reset() {
    window.grecaptcha.enterprise.reset()
    window.recaptchaResponse = this.recaptchaResponse
  }
}
</script>
