






























































import { Component, Vue } from 'vue-property-decorator'
import Success from '@/components/Success.vue'
import Otp from '@/components/facts/Otp.vue'
import BackButton from '@/components/layout/BackButton.vue'
import NextButton from '@/components/layout/NextButton.vue'
import ImportantNote from '@/components/ImportantNote.vue'
import { Auth } from '@/services/Auth'
import constants from '@/constants'
import { TranslateResult } from 'vue-i18n'
import { Shift } from '@/services/Shift'
import Recaptcha from '@/components/Recaptcha.vue'
import { RecaptchaSiteKeyService } from '@/services/RecaptchaSiteKeyService'
import { Action } from 'vuex-class'
import { ACTION_SET_POLICY_HOLDER } from '@/store/modules/user/actionTypes'

@Component({
  components: {
    Recaptcha,
    Otp,
    Success,
    BackButton,
    NextButton,
    ImportantNote
  }
})
export default class OTPAuth extends Vue {
  @Action(ACTION_SET_POLICY_HOLDER)
  setPolicyHolder!: () => void

  private captchaEnabled = false
  private recaptchaToken = ''
  private submitDisabled = true
  private otpLength = 6
  private otp?: string
  private flashMessage: TranslateResult = ''
  private flashTimer: any
  private routes = constants.routeNames

  protected async mounted() {
    this.captchaEnabled = await RecaptchaSiteKeyService.getSiteKey() !== ''
  }

  async submitOtp() {
    this.hideFlash()

    if (this.captchaEnabled) {
      const ident = window.sessionStorage.getItem('otp-ident') || ''
      const result = await Shift.verifyRecaptchaToken(this.recaptchaToken, ident)

      if (!result.valid) {
        this.flash('applicationOtp.info.recaptchaFailed')
        return
      }
    }

    if (!(await Auth.authenticate('otp', { secret: this.otp })).success) {
      this.otp = ''
      this.recaptchaToken = ''
      this.updateSubmitButtonStatus()

      if (this.captchaEnabled) {
        const recaptcha = this.$refs?.recaptcha as Recaptcha
        recaptcha.reset()
      }
      const next = this.$refs?.next as NextButton
      next.reset()

      this.$showErrorPopUp(this.$t('applicationOtp.info.otpLoginError').toString())

      return
    }
    this.setPolicyHolder()

    await this.$router.push({ name: constants.routeNames.APPLICATION_DEVICE })
  }

  verifyRecaptchaToken(recaptchaToken: string) {
    this.recaptchaToken = recaptchaToken

    this.updateSubmitButtonStatus()
  }

  backToEmail() {
    this.$router.push({ name: constants.routeNames.APPLICATION_EMAIL })
  }

  goToFaq() {
    this.$modal.show(constants.modalNames.FAQ_MODAL)
  }

  flash(msg: string) {
    this.flashMessage = this.$t(msg)
    this.flashTimer = setTimeout(() => {
      this.flashMessage = ''
    }, 30000) // Hide message after 30 seconds
  }

  hideFlash() {
    clearTimeout(this.flashTimer)
    this.flashMessage = ''
  }

  private input(otp: string) {
    this.otp = otp

    this.updateSubmitButtonStatus()
  }

  updateSubmitButtonStatus() {
    this.submitDisabled = (this.captchaEnabled && this.recaptchaToken.length === 0) || (this.otp?.length !== this.otpLength)
  }
}
