



















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  name: 'OTP'
})
export default class OTP extends Vue {
  @Prop() private length!: number

  private otpValues: number[] = Array(this.length)

  onPaste(evt: ClipboardEvent) {
    const pasted = evt.clipboardData?.getData('text').trim()
    if (pasted && pasted.length === this.length) {
      for (let i = 0; i < pasted.length; i++) {
        const char = pasted.charAt(i)
        if (!isNaN(parseInt(char, 10))) {
          this.otpValues[i] = parseInt(char, 10)
        } else {
          this.otpValues = Array(this.length)
          break
        }
      }
    } else {
      this.otpValues = Array(this.length)
    }
    this.$forceUpdate()
    evt.preventDefault()
    const target = evt.target as HTMLInputElement
    target.blur()
    this.$emit('input', this.otpValues.join(''))
  }

  private onFocus(e: Event) {
    const target = e.target as HTMLInputElement
    target.setSelectionRange(0, target.value.length)
  }

  private onInput(e: Event) {
    const target = e.target as HTMLInputElement
    if (target && target.id) {
      const id = parseInt(target.id[target.id.length - 1])
      if (target.value && target.value !== '') {
        if (isNaN(parseInt(target.value, 10))) {
          target.value = ''
          return
        }
        if (id < this.length - 1) {
          const ref = this.$refs[`otp${id + 1}`] as HTMLInputElement[]
          if (ref && ref.length === 1) {
            ref[0].focus()
          }
        }
      } else {
        if (['backspace', 'delete'].includes((e as KeyboardEvent).key?.toLowerCase())) {
          const ref = this.$refs[`otp${id - 1}`] as HTMLInputElement[]
          if (ref && ref.length === 1) {
            ref[0].focus()
          }
        }
      }
      this.$emit('input', this.otpValues.join(''))
    }
  }
}
